<template>

  <v-menu v-model="show" offset-y>
    <template v-slot:activator="{on}">
      <div class="btnLanguage">
        <v-progress-circular size="16" class="loadingLanguage" indeterminate color="primary" v-if="loading"></v-progress-circular>
        <v-btn icon v-on="on" class="mr-0 ">
          <v-icon>mdi-translate</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list>
      <v-list-item color="success" link v-for="(lang, i) in languages" :key="i" @click="changeLang(lang.lang)">
        <v-list-item-icon>
          <v-img width="32" :src="require('@/Assets/Images/Interface/Flags/' + lang.flag)"></v-img>
        </v-list-item-icon>
        <v-list-item-title>
          {{ lang.title }}
        </v-list-item-title>
        <v-list-item-icon v-if="currentLang == lang.lang">
          <v-progress-circular size="16" indeterminate color="primary" v-if="loading"></v-progress-circular>
          <v-icon color="success" v-else>mdi-check</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Vuex from "vuex"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import service from "@/Services/TraductionService";
import UserService from "@/Services/UserService";

export default {
  name: "Languages",
  computed: {
    ...Vuex.mapState(["preferences", "commons"]),
    currentLang() {
      return this.preferences.lang
    },
    loading() {
      let loading = true
      if (this.commons != undefined && this.commons.loadingLanguage != undefined) {
        loading = this.commons.loadingLanguage
      }
      return loading
    }
  },
  data() {
    // fr-FR, en, de, it, nl, fr-co, pt, es
    return {
      show: false,
      languages: [
        {
          lang: "fr",
          flag: "flag-france.svg",
          title: "Français"
        },
        {
          lang: "en",
          flag: "flag-england.svg",
          title: "English"
        },
        {
          lang: "ca",
          flag: "flag-canada.svg",
          title: "Canadian"
        },
        {
          lang: "fr-CO",
          flag: "flag-corse.svg",
          title: "Corse"
        },
        {
          lang: "de",
          flag: "flag-deutsch.svg",
          title: "Deutsch"
        },
        {
          lang: "it",
          flag: "flag-italia.svg",
          title: "Italia"
        },
        {
          lang: "nl",
          flag: "flag-nederlands.svg",
          title: "Nederlands"
        },
        {
          lang: "pt",
          flag: "flag-portugues.svg",
          title: "Português"
        },
        {
          lang: "es",
          flag: "flag-spain.svg",
          title: "Español"
        },
        {
          lang: "se",
          flag: "flag-sweden.svg",
          title: "Svenska"
        },
      ]
    }
  },
}
</script>

<style scoped>
  .btnLanguage {
    position: relative;
  }
  .loadingLanguage {
    position: absolute;
    right: 4px;
    bottom: 4px;
    width: 100%; height: 100%;
    margin: auto;
  }
</style>
